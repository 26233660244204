import useModalStore from '~/composables/useModalStore';

const activePDFids: number[] = [];
const loadingPDFids: number[] = [];

const characterImages = new Array<HTMLImageElement>();
const missionImages = new Array<HTMLImageElement>();

export default defineStore('play', {
    state: () => ({
        wpNonce: null,
        characters: [] as Character[],
        missions: [] as Mission[],
        lastMousePoint: [0, 0],
        logClicks: false,
        activePDFids,
        loadingPDFids,
        loading: false,
        initialLoad: true,
    }),
    getters: {
        loadingPDFs(state) {
            return state.loadingPDFids.length > 0;
        },
    },
    actions: {
        setLoading(loading) {
            this.loading = loading;
        },
        setInitialLoad(payload) {
            this.initialLoad = payload;
        },
        setLastMousePoint(point) {
            this.lastMousePoint = point;
        },
        addActivePDFid(id) {
            this.activePDFids.push(id);
        },
        removeActivePDFid(id: number) {
            this.activePDFids = this.activePDFids.filter(theid => theid != id);
        },
        addLoadingPDFid(id: number) {
            this.loadingPDFids.push(id);
        },
        removeLoadingPDFid(id: number) {
            this.loadingPDFids = this.loadingPDFids.filter(theid => theid != id);
        },

        /* Actions */
        async loadCharacters() {
            const { data: characters } = await useRestAPI<Character[]>('/api/characters');
            if (!characters.value) return;
            this.characters = characters.value;
            this.preloadCharacterImages();
        },
        async preloadCharacterImages() {
            if (import.meta.server) return;
            this.characters.forEach(({ image }) => {
                const newImage = (new Image());
                newImage.src = image;
                characterImages.push(newImage);
            });
        },
        async loadMissions() {
            const { data: missions } = await useRestAPI<Mission[]>('/api/missions');
            if (!missions.value) return;
            this.missions = missions.value;
            this.preloadMissionImages();
        },
        async preloadMissionImages() {
            this.missions.forEach(({ image }) => {
                const newImage = (new Image());
                newImage.src = image;
                missionImages.push(newImage);
            });
        },
        async loadPDF({ element, type, broadcast = false }: { element: Character | Mission; type: PDFTYPE; broadcast?: boolean },
        ) {
            const backendService = useBackendService();
            const paperObjectGenerator = usePaperObjectsGenerator();
            this.addActivePDFid(element.ID);
            this.addLoadingPDFid(element.ID);

            if (broadcast) {
                let event;
                if (type === PDFTYPE.MISSION) event = 'load-mission-pdf';
                else if (type === PDFTYPE.CHARACTER) event = 'load-character-pdf';

                backendService.broadcast({
                    event,
                    payload: element,
                });
            }

            await paperObjectGenerator.loadPDFIntoCanvas(element, type);
            this.removeLoadingPDFid(element.ID);
            const modals = useModalStore();
            if (type == PDFTYPE.CHARACTER) {
                modals.characterSelectModal = false;
            }
            else if (type == PDFTYPE.MISSION) {
                modals.missionSelectModal = false;
            }
        },
    },
});
