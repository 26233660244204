import { io } from 'socket.io-client';
import type { Socket } from 'socket.io-client';
import type { DefaultEventsMap } from 'socket.io/dist/typed-events';

let socket: undefined | Socket<DefaultEventsMap, DefaultEventsMap> = undefined;
export function useSocketIO() {
    function connect() {
        socket = io();
        socket.on('connect', () => {
            const session = useMultiplayerSessionStore();
            session.socketId = socket?.id ?? '';
            session.connectedToBackend = true;
        });
    }

    // Function to set up listeners for socket.io events and transfer them to the event bus
    function on(event, callback) {
        socket?.on(event, callback);
    }

    function off(event, callback) {
        socket?.off(event, callback);
    }

    function emit(event, payload) {
        socket?.emit(event, payload);
    }

    function onAny(callback) {
        socket?.onAny((event, ...args) => {
            callback(event, ...args);
        });
    }

    // TODO unregister + cleanup all listeners on component unmount

    return { on, off, emit, $socket: socket, onAny, connect };
}
