<script lang="ts" setup>
defineProps({
    loadingCondition: {
        type: Boolean,
    },
    fullWidth: {
        type: Boolean,
        default: false,
    },
    fullHeight: {
        type: Boolean,
        default: false,
    },
    noPanelPadding: {
        type: Boolean,
        default: false,
    },
    initialFocus: {
        default: undefined,
    },
});
const active = defineModel('active', { type: Boolean });

const handleClose = () => {
    active.value = false;
};
</script>

<template>
    <HeadlessTransitionRoot
        :show="active"
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
    >
        <HeadlessDialog
            class="fixed top-0 left-0 h-full w-full z-50"
            :initial-focus
            @click="handleClose"
            @close="handleClose"
        >
            <HeadlessTransitionChild
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <HeadlessDialogOverlay class="fixed inset-0 bg-gray-900 opacity-75 cursor-pointer" />
            </HeadlessTransitionChild>
            <HeadlessTransitionChild
                class="h-full fixed left-0 top-0 w-full p-6 md:p-12 flex items-center justify-center pointer-events-none"
                enter="duration-300 ease-out"
                enter-from="opacity-0 translate-y-8"
                enter-to="opacity-100 translate-y-0"
                leave="duration-200 ease-in"
                leave-from="opacity-100 translate-y-0"
                leave-to="opacity-0 translate-y-8"
            >
                <HeadlessDialogPanel
                    :class="{
                        'w-full': fullWidth,
                        'h-full': fullHeight,
                    }"
                    class="rounded shadow-lg bg-white h-auto max-h-full pointer-events-auto"
                >
                    <div
                        v-if="loadingCondition"
                        class="loading-overlay absolute inset-0 z-[100] flex items-center justify-center bg-black opacity-75"
                    >
                        <SvgoTailSpin
                            alt="Loading"
                            class="w-16 max-w-full mx-auto"
                        />
                    </div>

                    <div
                        class="relative z-50 overflow-y-auto h-full flex flex-col gap-4"
                        :class="{
                            'px-4 md:px-10 py-6 max-h-[90vh]': !noPanelPadding,
                        }"
                    >
                        <button
                            aria-label="Modal schließen"
                            class="modal-close z-50 cursor-pointer w-6 h-6 absolute top-4 right-4"
                            @click="handleClose"
                        >
                            <slot name="closeIcon">
                                <SvgoCross class="w-full h-full fill-current" />
                            </slot>
                        </button>
                        <!-- Title -->
                        <HeadlessDialogTitle
                            v-if="$slots.title?.()"
                            :as="'h1'"
                            class="text-4xl font-display"
                        >
                            <slot name="title" />
                        </HeadlessDialogTitle>

                        <!-- Body -->
                        <div class="flex-grow">
                            <slot />
                        </div>
                    </div>
                </HeadlessDialogPanel>
            </HeadlessTransitionChild>
        </HeadlessDialog>
    </HeadlessTransitionRoot>
</template>
