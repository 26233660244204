import type { FetchContext } from 'ofetch';
import { getJwt } from '~/utils/get-parameters';

export function useRestAPI<T>(route: string) {
    const jwt = getJwt();

    return useFetch<T>(route, {
        credentials: 'include',
        onRequest(context: FetchContext): Promise<void> | void {
            context.options.headers = context.options.headers || {};

            if (jwt) {
                context.options.headers['Authorization'] = 'Bearer ' + jwt;
                return;
            }
        },
    });
}
