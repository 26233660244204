export default defineStore('user', {
    state: () => ({
        name: 'Spielername',
        color: '#333',
    }),
    getters: {
        serialized: state => ({
            name: state.name,
        }),
    },
    actions: {
        setPlayer(player) {
            this.$patch({
                ...player,
            });
        },
    },
});
